import { CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useMemo } from 'react';

import Routing from './components/Routing';

// Create a client
const queryClient = new QueryClient();

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const getDesignTokens = (prefersDarkMode) => ({
    palette: {
      ...(!prefersDarkMode
        ? {
            // palette values for light mode
            primary: { main: '#0081C9' },
            secondary: { main: '#5BC0F8' },
          }
        : {
            // palette values for dark mode
            primary: { main: '#0081C9' },
            secondary: { main: '#5BC0F8' },
          }),
    },
  });

  const theme = useMemo(
    () =>
      createTheme({
        ...getDesignTokens(prefersDarkMode),
        typography: {
          useNextVariants: true,
        },
        components: {
          MuiContainer: {
            defaultProps: {
              maxWidth: 'lg',
              padding: { xs: 2, sm: 4 },
            },
          },
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <Routing />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
