import { Button, Typography } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { auth } from '../../../helpers/Firebase';

const SignIn = (props) => {
  const handleSignIn = async () => {
    await signInWithPopup(auth, new GoogleAuthProvider());
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Anmelden
      </Typography>
      <Button onClick={handleSignIn}>Anmelden</Button>
    </>
  );
};

export default SignIn;
