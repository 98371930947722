import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
} from '@mui/material';
import { formatDistanceToNow, format } from 'date-fns';
import { de } from 'date-fns/locale';

import { JOB_CATEGORIES } from '../../constants';

const JobsForApprovalTable = ({ jobs, onApprovalClick }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Firma</TableCell>
            <TableCell>Job</TableCell>
            <TableCell>Kategorie</TableCell>
            <TableCell>Tarif</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.length > 0 ? (
            jobs.map(({ id, data }) => (
              <TableRow
                key={id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  title={format(data.submittedAt.toDate(), 'PPPPpppp', {
                    locale: de,
                  })}
                >
                  {formatDistanceToNow(data.submittedAt.toDate(), {
                    locale: de,
                    addSuffix: true,
                  })}
                </TableCell>
                <TableCell>{data.companyName}</TableCell>
                <TableCell>{data.title}</TableCell>
                <TableCell>{JOB_CATEGORIES[data.category]}</TableCell>
                <TableCell>{data.plan}</TableCell>
                <TableCell>
                  <Button onClick={() => onApprovalClick(id)}>
                    Freischalten
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <Typography>
                  Sobald ein Job eingereicht wird, taucht er hier auf.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default JobsForApprovalTable;
