import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
} from '@mui/material';

import NoImage from '../../assets/images/no-image.svg';
const CompaniesTable = ({ companies, onEditClick }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.length > 0 ? (
            companies.map(({ id, data: { name, logo } }) => (
              <TableRow
                key={id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ width: 40 }}>
                  <Box
                    component="img"
                    src={logo || NoImage}
                    alt={name}
                    sx={{ width: 40 }}
                  />
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell align="right">
                  <Button
                    href={`https://digital-insurance-jobs.com/companies/${id}`}
                    target="_blank"
                    startIcon={<OpenInNewIcon />}
                  >
                    Öffentliches Profil
                  </Button>
                  <Button onClick={() => onEditClick(id)}>Bearbeiten</Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography>
                  Es wurde noch kein Unternehmen angelegt.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompaniesTable;
