import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, createRef, forwardRef, useEffect } from 'react';

import NoImage from '../../assets/images/no-image.svg';

const ImageUpload = forwardRef(
  (
    { disabled = false, accept = 'image/png, image/jpeg', onChange, value },
    ref,
  ) => {
    const [highlighted, setHighlighted] = useState(false);
    const [image, setImage] = useState(value);

    useEffect(() => {
      if (typeof value === 'string') {
        setImage(value);
      } else if (typeof value === 'object') {
        setImage(URL.createObjectURL(value));
      }
    }, [value]);

    const fileInputRef = createRef();

    const handleOpenFileDialog = () => {
      if (disabled) return;
      fileInputRef.current.click();
    };

    const handleFilesAdded = (event) => {
      if (disabled) return;

      if (event.target.files[0]) {
        const { files } = event.target;
        if (onChange) {
          onChange(files[0]);
        }

        setImage(URL.createObjectURL(files[0]));
      } else {
        setImage();
      }
    };

    const handleDragOver = (evt) => {
      evt.preventDefault();

      if (disabled) return;

      setHighlighted(true);
    };

    const handleDragLeave = () => {
      setHighlighted(false);
    };

    const handleDrop = (event) => {
      event.preventDefault();

      if (disabled) return;

      if (event.dataTransfer.files[0]) {
        const { files } = event.dataTransfer;
        if (onChange) {
          onChange(files[0]);
        }

        setImage(URL.createObjectURL(files[0]));
      } else {
        setImage();
      }

      setHighlighted(false);
    };

    return (
      <Box
        sx={{
          height: '200px',
          width: '200px',
          border: '2px dashed',
          borderColor: 'text.secondary',
          borderRadius: '5%',
          cursor: disabled ? 'default' : 'pointer',
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleOpenFileDialog}
        ref={ref}
      >
        <Box
          component="input"
          ref={fileInputRef}
          sx={{ display: 'none' }}
          type="file"
          accept={accept}
          multiple={false}
          onChange={handleFilesAdded}
        />
        <Box
          component="img"
          src={image || NoImage}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: 'inherit',
            filter: highlighted ? 'brightness(50%)' : 'brightness(100%)',
          }}
        />
      </Box>
    );
  },
);

ImageUpload.propTypes = {
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ImageUpload;
