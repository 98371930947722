import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Typography,
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { doc, deleteDoc } from 'firebase/firestore';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link as LinkRouter } from 'react-router-dom';

import LoadingWrapper from '../../components/LoadingWrapper';
import { FRONTEND_URL } from '../../constants';
import { JOBS_COLLECTION } from '../../helpers/collections';
import useDeployFrontendMutation from '../../hooks/useDeployFrontendMutation';
import useJobsQuery from '../../hooks/useJobsQuery';
const Companies = () => {
  const { isLoading, isError, isSuccess, data, refetch } = useJobsQuery();
  const deployFrontendMutation = useDeployFrontendMutation();
  const [jobToBeDeleted, setJobToBeDeleted] = useState(undefined);
  const [deleteDialogOopen, setSeleteDialogOopen] = useState(false);

  const askForDelete = (job) => {
    setJobToBeDeleted(job);
    setSeleteDialogOopen(true);
  };

  const onCancelDelete = () => {
    setSeleteDialogOopen(false);
    setJobToBeDeleted(undefined);
  };

  const onConfirmDelete = async () => {
    // Close dialog
    setSeleteDialogOopen(false);

    // Delete job in database
    await deleteDoc(doc(JOBS_COLLECTION, jobToBeDeleted.id));

    // Reset job to be deleted
    setJobToBeDeleted(undefined);

    // Refetch jobs data
    refetch();

    // Trigger frontend deployment
    deployFrontendMutation.mutate();
  };

  const dateSort = (rowA, rowB) =>
    rowA.data.publishedAt.toDate() - rowB.data.publishedAt.toDate();

  const caseInsensitiveSort = (valueA, valueB) => {
    const a = valueA.toLowerCase();
    const b = valueB.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: 'Veröffentlicht am',
      selector: (row) =>
        format(row.data.publishedAt.toDate(), 'P', {
          locale: de,
        }),
      sortable: true,
      sortFunction: dateSort,
      grow: 2,
    },
    {
      name: 'Unternehmen',
      selector: (row) => (
        <Link component={LinkRouter} to={`/companies/${row.data.company.id}`}>
          {row.data.company.data.name}
        </Link>
      ),
      sortable: true,
      grow: 2,
      sortFunction: (rowA, rowB) =>
        caseInsensitiveSort(
          rowA.data.company.data.name,
          rowB.data.company.data.name,
        ),
    },
    {
      name: 'Titel',
      selector: (row) => row.data.title,
      sortable: true,
      grow: 10,
    },
    {
      name: 'Kateorie',
      selector: (row) => row.data.category,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Tarif',
      selector: (row) => row.data.plan,
      sortable: true,
      grow: 1,
    },
    {
      name: 'Bewerber',
      selector: (row) => (row.data.clicked ? row.data.clicked.length : 0),
      sortable: true,
      grow: 1,
    },
    {
      selector: (row) => (
        <Button
          href={`${FRONTEND_URL}/jobs/${row.id}`}
          target="_blank"
          startIcon={<OpenInNewIcon />}
        >
          Ansehen
        </Button>
      ),
      width: '130px',
    },
    {
      selector: (row) => (
        <Button
          onClick={() => {
            askForDelete(row);
          }}
        >
          Löschen
        </Button>
      ),
      width: '120px',
    },
  ];

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Jobs
      </Typography>
      <LoadingWrapper
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
      >
        <DataTable
          columns={columns}
          data={data?.jobs}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Jobs pro Seite',
            rangeSeparatorText: 'von',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Alle',
          }}
          paginationPerPage={20}
          defaultSortFieldId={1}
          defaultSortAsc={false}
        />
      </LoadingWrapper>
      <Dialog
        open={deleteDialogOopen}
        onClose={onCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          "{jobToBeDeleted?.data.title}" löschen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchtest du <strong>{jobToBeDeleted?.data.title}</strong> von{' '}
            <strong>{jobToBeDeleted?.data.company.data.name}</strong> löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelDelete}>Nein</Button>
          <Button onClick={onConfirmDelete} autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Companies;
