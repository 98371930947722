import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AdminLayout from '../../components/ProtectiveLayout';
import Companies from '../../containers/Companies';
import Company from '../../containers/Company';
import Jobs from '../../containers/Jobs';
import JobsForApproval from '../../containers/JobsForApproval/JobsForApproval';

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<JobsForApproval />} />
        <Route path="companies" element={<Companies />} />
        <Route path="companies/:id" element={<Company />} />
        <Route path="jobs" element={<Jobs />} />
      </Route>
    </Routes>
  );
};

export default Routing;
