import BusinessIcon from '@mui/icons-material/Business';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link as LinkRouter } from 'react-router-dom';

const drawerWidth = 240;

export default function ClippedDrawer({ children }) {
  /**
   * Configures all menu items to be rendered
   */
  const menuItems = [
    { label: 'Jobs bestätigen', icon: <ChecklistIcon />, to: '/' },
    { label: 'Alle Jobs', icon: <ListAltIcon />, to: '/jobs' },
    { label: 'Unternehmen', icon: <BusinessIcon />, to: '/companies' },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Digital Insurance Jobs Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {
              /**
               * Renders all menu items
               */

              menuItems.map(({ label, icon, to }, i) => (
                <ListItem disablePadding key={i}>
                  <ListItemButton component={LinkRouter} to={to}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
