import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  runTransaction,
  addDoc,
} from 'firebase/firestore';

import useDeployFrontendMutation from './useDeployFrontendMutation';
import {
  COMPANIES_COLLECTION,
  JOB_REQUEST_COLLECTION,
  JOBS_COLLECTION,
  getContactPersonCollectionByCompanyId,
} from '../helpers/collections';
import { db } from '../helpers/Firebase';

const useApproveJobRequestMutation = (options = {}) => {
  const queryClient = useQueryClient();
  const deployFrontendMutation = useDeployFrontendMutation();

  const customOnSuccess = options.onSuccess;
  delete options.onSuccess;

  return useMutation({
    mutationFn: (id) =>
      runTransaction(db, async (transaction) => {
        const jobRequestRef = doc(JOB_REQUEST_COLLECTION, id);
        const jobRequestSnap = await getDoc(jobRequestRef);

        const {
          plan,
          billingAddress,
          billingEmail,
          category,
          company,
          companyName,
          contactPerson,
          description,
          email,
          location,
          phone,
          target,
          title,
          website,
        } = jobRequestSnap.data();

        const q = query(
          COMPANIES_COLLECTION,
          where('billingName', '==', companyName),
        );

        const querySnapshot = await getDocs(q);

        let companyRef;
        if (querySnapshot.empty) {
          companyRef = await addDoc(COMPANIES_COLLECTION, {
            billingAddress,
            billingEmail,
            billingName: companyName,
            name: company,
            website,
          });
        } else {
          companyRef = querySnapshot.docs[0].ref;
        }

        await addDoc(getContactPersonCollectionByCompanyId(companyRef.id), {
          name: contactPerson,
          email,
          phone,
        });

        await addDoc(JOBS_COLLECTION, {
          company: companyRef,
          title,
          target,
          description,
          category,
          location,
          plan,
          publishedAt: new Date(),
        });

        deleteDoc(jobRequestRef);
      }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['jobs'] });

      // Trigger frontend deployment
      deployFrontendMutation.mutate();

      // Call any additional onSuccess function, if defined
      if (typeof customOnSuccess === 'function') {
        customOnSuccess();
      }
    },
    ...options,
  });
};

export default useApproveJobRequestMutation;
