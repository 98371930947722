import { useMutation, useQueryClient } from '@tanstack/react-query';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import useDeployFrontendMutation from './useDeployFrontendMutation';
import { COMPANIES_COLLECTION } from '../helpers/collections';
import { getCompanyStorage } from '../helpers/storages';

const useCompanyMutation = (options = {}) => {
  const queryClient = useQueryClient();
  const deployFrontendMutation = useDeployFrontendMutation();

  const customOnSuccess = options.onSuccess;
  delete options.onSuccess;

  return useMutation({
    mutationFn: (data) =>
      new Promise(async (resolve, reject) => {
        // If the logo is not a string, ...
        if (typeof data.data.logo !== 'string') {
          // ... it probably needs uploading.

          const getFileEnding = () => {
            const parts = data.data.logo.name.split('.');
            return parts.pop();
          };

          const fileRef = ref(
            getCompanyStorage(data.id),
            `logo.${getFileEnding()}`,
          );
          const uploadTask = uploadBytesResumable(fileRef, data.data.logo);

          // We wait for the upload to finish, before...
          await new Promise(async (resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              },
              (error) => {
                // ... rejecting in case of an error or...
                reject(error);
              },
              async () => {
                // ... assigning the logo's download URL to the original logo prop.
                data.data.logo = await getDownloadURL(uploadTask.snapshot.ref);
                resolve();
              },
            );
          });
        }

        // Then we update the doc in Firestore
        await updateDoc(doc(COMPANIES_COLLECTION, data.id), data.data);
        resolve();
      }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['companies'] });

      if (process.env.NODE_ENV === 'production') {
        // Trigger frontend deployment
        deployFrontendMutation.mutate();
      }
      // Call any additional onSuccess function, if defined
      if (typeof customOnSuccess === 'function') {
        customOnSuccess();
      }
    },
    ...options,
  });
};

export default useCompanyMutation;
