import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import CompaniesTable from './CompaniesTable';
import LoadingWrapper from '../../components/LoadingWrapper';
import useCompaniesQuery from '../../hooks/useCompaniesQuery';

const Companies = () => {
  const navigate = useNavigate();
  const { isLoading, isError, isSuccess, data } = useCompaniesQuery();

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Unternehmen
      </Typography>
      <LoadingWrapper
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
      >
        <CompaniesTable companies={data} onEditClick={(id) => navigate(id)} />
      </LoadingWrapper>
    </>
  );
};

export default Companies;
