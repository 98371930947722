import { Container, Stack, CircularProgress } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import SignIn from './SignIn/SignIn';
import { auth } from '../../helpers/Firebase';
import MenuDrawer from '../MenuDrawer';

const SIGNED_IN = 'SIGNED_IN';
const SIGNED_OUT = 'SIGNED_OUT';
const LOADING = 'LOADING';

const Layout = () => {
  const [state, setState] = useState(LOADING);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setState(SIGNED_IN);
      } else {
        setState(SIGNED_OUT);
      }
    });
  }, []);

  if (state === LOADING) {
    return (
      <Stack mt={2} alignItems="center">
        <CircularProgress />
      </Stack>
    );
  } else if (state === SIGNED_IN) {
    return (
      <MenuDrawer>
        <Container
          maxWidth="lg"
          sx={{
            padding: { xs: 2, sm: 4 },
          }}
        >
          <Outlet />
        </Container>
      </MenuDrawer>
    );
  } else {
    return (
      <Container
        maxWidth="lg"
        sx={{
          padding: { xs: 2, sm: 4 },
        }}
      >
        <SignIn />
      </Container>
    );
  }
};

export default Layout;
