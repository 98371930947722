import { Box, FormHelperText } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WYSIWYGEditor = React.forwardRef(({ error, ...props }, ref) => {
  return (
    <Box
      sx={{
        '.ql-container, .ql-toolbar': error && {
          borderColor: 'error.main',
          color: 'error.main',
        },
        '.ql-editor': { minHeight: '250px' },
      }}
    >
      <ReactQuill {...props} ref={ref}></ReactQuill>
      {error && <FormHelperText error={error}>{error.message}</FormHelperText>}
    </Box>
  );
});

export default WYSIWYGEditor;
