import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Box, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import ImageUpload from '../../components/ImageUpload';
import LoadingWrapper from '../../components/LoadingWrapper';
import WYSIWYGEditor from '../../components/WYSIWYGEditor';
import { LINKEDIN_PAGE_URL_REGEX } from '../../constants';
import useCompaniesQuery from '../../hooks/useCompaniesQuery';
import useCompanyMutation from '../../hooks/useCompanyMutation';

const Company = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading, isError, isSuccess, data } = useCompaniesQuery({
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
  });

  let company = {};
  if (isSuccess) {
    company = data.find((c) => c.id === id);
  }

  const { mutateAsync } = useCompanyMutation({
    onSuccess: () => navigate('/companies'),
  });

  const {
    formState: { isSubmitting },
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    values: {
      ...company?.data,
    },
  });

  return (
    <>
      <LoadingWrapper
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
      >
        <Typography variant="h2" gutterBottom>
          {watch('name')}
        </Typography>
        <form
          onSubmit={handleSubmit((data) =>
            mutateAsync({
              id,
              data,
            }),
          )}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, 1fr)',
              gridTemplateRows: 'repeat(9, min-content)',
              gridColumnGap: (theme) => theme.spacing(2),
              gridRowGap: (theme) => theme.spacing(2),
            }}
          >
            <Box sx={{ gridArea: '1 / 1 / 2 / 7' }}>
              <Typography variant="h4" sx={{ marginTop: 3 }}>
                Öffentliche Daten
              </Typography>
            </Box>
            <Box sx={{ gridArea: '2 / 1 / 5 / 3' }}>
              <Controller
                render={({ field: { onChange, ...fields } }) => (
                  <ImageUpload
                    onChange={(file) => onChange(file)}
                    {...fields}
                  />
                )}
                name="logo"
                control={control}
              />
            </Box>
            <Box sx={{ gridArea: '2 / 3 / 3 / 7' }}>
              <TextField
                variant="standard"
                label="Name"
                error={errors.name}
                {...register('name', { required: true })}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ gridArea: '3 / 3 / 4 / 7' }}>
              <TextField
                variant="standard"
                label="Website"
                error={errors.website}
                {...register('website', { required: true })}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ gridArea: '4 / 3 / 5 / 7' }}>
              <TextField
                variant="standard"
                label="URL der LinkedIn-Page"
                error={errors.linkedInPageURL}
                {...register('linkedInPageURL', {
                  required: true,
                  pattern: {
                    value: LINKEDIN_PAGE_URL_REGEX,
                    message:
                      "Stimmt die URL? Die URL ist beispielsweise 'https://www.linkedin.com/showcase/digital-insurance-jobs/'",
                  },
                })}
                helperText={errors.linkedInPageURL?.message}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ gridArea: '5 / 1 / 6 / 7' }}>
              <Controller
                render={({ field }) => (
                  <WYSIWYGEditor error={errors.description} {...field} />
                )}
                name="description"
                control={control}
                required
                rules={{
                  validate: {
                    required: (v) =>
                      (v && v.length > 0) || 'Bitte füge einen Text hinzu.',
                    maxLength: (v) =>
                      // Count length of string stripped of all html tags
                      (v && v.replace(/<[^>]*>/g, '').length <= 10000) ||
                      'Bitte verwende nicht mehr als 10.000 Zeichen.',
                  },
                }}
              />
            </Box>
            <Box sx={{ gridArea: '6 / 1 / 7 / 7' }}>
              <Typography variant="h4" sx={{ marginTop: 3 }}>
                Rechnungsdaten
              </Typography>
            </Box>
            <Box sx={{ gridArea: '7 / 1 / 8 / 4' }}>
              <TextField
                variant="standard"
                label="Name des Unternehmens"
                error={errors.billingName}
                {...register('billingName', { required: true })}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ gridArea: '7 / 4 / 8 / 7' }}>
              <TextField
                variant="standard"
                type="email"
                label="E-Mail-Adresse für Rechnungen"
                error={errors.billingEmail}
                {...register('billingEmail', { required: true })}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ gridArea: '8 / 1 / 9 / 7' }}>
              <TextField
                variant="standard"
                label="Rechnungsanschrift"
                multiline
                rows={4}
                error={errors.billingAddress}
                {...register('billingAddress', { required: true })}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ gridArea: '9 / 1 / 10 / 7' }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Speichern
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </LoadingWrapper>
    </>
  );
};

export default Company;
