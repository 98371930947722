import { collection } from 'firebase/firestore';

import { db } from './Firebase';

export const JOBS_COLLECTION = collection(db, 'jobs');
export const JOB_REQUEST_COLLECTION = collection(db, 'jobRequests');
export const COMPANIES_COLLECTION = collection(db, 'companies');
/**
 * Retrieves the firestore contact people collection for a specified company.
 *
 * @param {String} companyId The id of the company for which we need the contact people collection.
 * @returns Firestore Collection
 */
export const getContactPersonCollectionByCompanyId = (companyId) => {
  return collection(COMPANIES_COLLECTION, companyId, 'contactPeople');
};
