export const JOB_CATEGORIES = {
  finanzen: 'Finanzen / Controlling / Rechnungswesen',
  hr: 'HR & Personal',
  innovation: 'Digitalisierung / Innovation',
  it: 'IT / Softwareentwicklung',
  kundenservice: 'Kundenservice / Leistung',
  marketing: 'Marketing / Kommunikation',
  produkt: 'Produktmangagement / Aktuariat',
  vertrieb: 'Vertrieb (Außen- und Innendienst)',
  compliance: 'Recht / Compliance',
  risiko: 'Risikomanagement',
  strategie: 'Unternehmensentwicklung / Strategie',
  sonstiges: 'Sonstiges',
};

export const FRONTEND_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : 'https://digital-insurance-jobs.com';

export const LINKEDIN_PAGE_URL_REGEX =
  /.*linkedin\.com\/(?:company|showcase)\/[-a-zA-Z0-9%_+#=]+\/.*/;
