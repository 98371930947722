import { useMutation } from '@tanstack/react-query';

/** Returns a reactQuery mutation that triggers a new pipeline to deploy the frontend */
const useDeployFrontendMutation = (options = {}) => {
  return useMutation({
    mutationFn: () => {
      if (process.env.NODE_ENV === 'production') {
        fetch(
          'https://cloudbuild.googleapis.com/v1/projects/digital-insurance-jobs/locations/europe-west1/triggers/Deploy-Frontend:webhook?key=AIzaSyDtzCRLDDdFR_WoePwCQqEholAjdSLTsNw&secret=jXXDiNY_Fl5RllO7wDPVJQAWbNKSqfTe&trigger=Deploy-Frontend&projectId=digital-insurance-jobs',
          {
            method: 'POST',
            body: JSON.stringify({}),
          },
        );
      } else {
        // eslint-disable-next-line no-console
        console.info(
          "Didn't trigger frontend deployment because we're running in development.",
        );
      }
    },
  });
};

export default useDeployFrontendMutation;
