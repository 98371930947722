import { useQuery } from '@tanstack/react-query';
import { getDocs } from 'firebase/firestore';

import { COMPANIES_COLLECTION } from '../helpers/collections';

const useCompaniesQuery = (options = {}) => {
  return useQuery({
    queryKey: ['companies'],
    queryFn: async () => {
      const companiesSnapshot = await getDocs(COMPANIES_COLLECTION);

      let result = [];
      companiesSnapshot.forEach((doc) => {
        result.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      return result;
    },
    ...options,
  });
};

export default useCompaniesQuery;
