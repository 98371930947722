import { useQuery } from '@tanstack/react-query';
import { getDocs } from 'firebase/firestore';

import { COMPANIES_COLLECTION, JOBS_COLLECTION } from '../helpers/collections';

const useJobsQuery = (options = {}) => {
  return useQuery({
    queryKey: ['jobs'],
    queryFn: async () => {
      const [jobsSnapshot, companiesSnapshot] = await Promise.all([
        getDocs(JOBS_COLLECTION),
        getDocs(COMPANIES_COLLECTION),
      ]);

      let result = {
        companiesCount: companiesSnapshot.docs.length,
        jobsCount: jobsSnapshot.docs.length,
        jobs: [],
      };
      jobsSnapshot.forEach((doc) => {
        const companyDoc = companiesSnapshot.docs.find(
          (docSnap) => docSnap.id === doc.data().company.id,
        );

        result.jobs.push({
          id: doc.id,
          data: {
            ...doc.data(),
            company: { id: companyDoc.id, data: companyDoc.data() },
          },
        });
      });

      return result;
    },
    ...options,
  });
};

export default useJobsQuery;
