import { Typography } from '@mui/material';
import { query, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import JobsForApprovalTable from './JobsForApprovalTable';
import { JOB_REQUEST_COLLECTION } from '../../helpers/collections';
import useApproveJobRequestMutation from '../../hooks/useApproveJobMutation';

const JobsForApproval = () => {
  const [jobs, setJobs] = useState([]);
  const { mutate } = useApproveJobRequestMutation();

  useEffect(() => {
    const q = query(JOB_REQUEST_COLLECTION);
    onSnapshot(q, (querySnapshot) => {
      setJobs(
        querySnapshot.docs.map((doc) => {
          return { id: doc.id, data: doc.data() };
        }),
      );
    });
  }, []);

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Jobs bestätigen
      </Typography>
      <JobsForApprovalTable jobs={jobs} onApprovalClick={(id) => mutate(id)} />
    </>
  );
};

export default JobsForApproval;
